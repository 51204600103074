<template>
	<div class="tab-warp">
		<div class="phone-content tab-container layout-justify-between layout-items-center">
			<router-link to="/phoneindex">
				<img class="tab-img" src="../assets/image/login-logo.png" alt="" />
			</router-link>
			<div class="tab-icon layout-items-center">
				<router-link to="/rank">
					<img src="../assets/phone/Group 427319556@2x.png" alt="" />
				</router-link>
				<router-link to="/phoneSearch">
					<img src="../assets/phone/icon／搜索@2x.png" v-show="isSearch" alt="" />
				</router-link>
				<router-link to="/login" v-if="isLogin == null">
					<img src="../assets/phone/People (人员)@2x.png" alt="" />
				</router-link>
				<el-popover placement="top" width="100" v-model="visible">
					<p>确定退出登录吗？</p>
					<div style="text-align: right; margin: 0">
						<el-button size="mini" type="text" @click="visible = false">取消</el-button>
						<el-button type="primary" size="mini" @click="logout">确定</el-button>
					</div>
					<div v-if="isLogin !== null" slot="reference" class="layout-items-center">
						<img class="avater" :src="avater" alt="" v-show="avater !== ''" />
						<img class="avater" src="../assets/image/avater.png" v-show="avater == ''" alt="" />
					</div>
				</el-popover>
				<router-link to="/phoneHistory" v-if="isLogin !== null">
					<img src="../assets/phone/Add-one (添加)@2x.png" alt="" />
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import VueCookies from "vue-cookies";
	import { getInfo } from "@/api/login";

	export default {
		data() {
			return {
				isSearch: false,
				isLogin: null,
				visible: false,
				avater: "",
			};
		},
		created() {
			this.updateTabIndexByRoute();
			this.getData();
			this.isLogin = VueCookies.get("video-token");
		},
		watch: {
			// 监听路由的变化，动态更新 tabIndex
			$route(to, from) {
				this.updateTabIndexByRoute();
				this.getData();
				this.isLogin = VueCookies.get("video-token");
			},
		},
		methods: {
			updateTabIndexByRoute() {
				const path = this.$route.path;
				// 如果路径是首页，tabIndex 设置为 -1
				if (path === "/phoneindex") {
					this.isSearch = false;
				} else {
					this.isSearch = true;
				}
			},
			getData() {
				if (VueCookies.get("video-token") !== "") {
					getInfo().then((res) => {
						if (res.code == 200) {
							this.avater = res.data.user.avatar;
						}
					});
				}
			},
			logout() {
				this.visible = false;
				VueCookies.remove("video-token");
				this.isLogin = VueCookies.get("video-token");
			},
		},
	};
</script>

<style lang="scss" scoped>
	.tab-warp {
		width: 100vw;
		position: fixed;
		top: 0;
		left: 0;
		background-color: white;
		z-index: 10;
		.tab-container {
			height: 44px;
			box-sizing: border-box;

			.tab-img {
				width: 98px;
				height: 28px;
				cursor: pointer;
			}
			.tab-icon {
				img {
					width: 24px;
					height: 24px;
					margin-left: 16px;
					cursor: pointer;
				}
			}
		}
	}
	.avater {
		width: 26px !important;
		height: 26px !important;
		border: 1px solid rgba(255, 255, 255, 0.5);
		border-radius: 50%;
	}
</style>
