<template>
	<div class="head-container" v-if="!isMobile">
		<div class="content layout-justify-between layout-items-center">
			<div class="layout-items-center" style="height: 100%">
				<router-link to="/">
					<div class="head-one layout-items-center" @click="chooseIndex(-1)">
						<img src="../assets/image/logo.png" alt="" />
					</div>
				</router-link>
				<div class="layout-flex head-two">
					<router-link to="/">
						<div class="head-content" :class="tabIndex === -1 ? 'actice' : ''" @click="chooseIndex(-1)">首页</div>
					</router-link>
					<div v-for="(item, index) in dataList">
						<router-link :to="{ name: 'movie', query: { synthesisId: item.synthesisId } }">
							<div class="head-content" :class="tabIndex === index ? 'actice' : ''" @click="chooseIndex(index)">
								{{ item.synthesisName }}
							</div>
						</router-link>
					</div>
					<!-- <router-link to="/movie">
						<div :class="tabIndex == 2 ? 'actice' : ''" @click="chooseIndex(2)">电影</div>
					</router-link>
					<div :class="tabIndex == 3 ? 'actice' : ''" @click="chooseIndex(3)">动漫</div>
					<div :class="tabIndex == 4 ? 'actice' : ''" @click="chooseIndex(4)">综艺</div>
					<div :class="tabIndex == 5 ? 'actice' : ''" @click="chooseIndex(5)">剧情</div>
					<router-link to="/recent">
						<div :class="tabIndex == 6 ? 'actice' : ''" @click="chooseIndex(6)">最新</div>
					</router-link> -->
				</div>
				<div class="search-content layout-items-center">
					<div class="search-btn layout-items-center">
						<img src="../assets/image/icon_sbox_search@2x.png" alt="" @click="onSearch" />
						<div class="search-cut"></div>
					</div>
					<input type="text" v-model="query" placeholder="请搜索" @keydown.enter="onSearch" />
				</div>
			</div>
			<div class="layout-items-center">
				<router-link to="/history" v-if="isLogin !== null">
					<span class="history layout-column layout-items-center">
						<img src="../assets/image/arcoDesign-history@3x.png" alt="" />
						<div class="history-txt">历史</div>
					</span>
				</router-link>

				<div class="login-btn layout-center" @click="dialogVisible = true" v-if="isLogin == null">
					<img src="../assets/image/login.png" alt="" />
					登录
				</div>

				<el-popover placement="top" width="100" v-model="visible" :key="isLogin">
					<p>确定退出登录吗？</p>
					<div style="text-align: right; margin: 0">
						<el-button size="mini" type="text" @click="visible = false">取消</el-button>
						<el-button type="primary" size="mini" @click="logout">确定</el-button>
					</div>
					<div v-if="isLogin !== null" slot="reference">
						<img class="avater" :src="avater" alt="" v-show="avater !== ''" />
						<img class="avater" src="../assets/image/avater.png" v-show="avater == ''" alt="" />
					</div>
				</el-popover>
			</div>
		</div>
		<!-- 登录 -->
		<el-dialog :visible.sync="dialogVisible" width="450px" :modal-append-to-body="false" :show-close="true" :close-on-click-modal="false">
			<img class="dialog-logo" src="../assets/image/login-logo.png" alt="" />
			<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm" label-position="top">
				<el-form-item label="账号" prop="username">
					<el-input type="text" v-model="ruleForm.username" autocomplete="off" placeholder="请输入账号"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入密码"></el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="code">
					<div class="layout-flex">
						<el-input v-model="ruleForm.code" class="input-content" placeholder="请输入验证码"></el-input>
						<img class="code-img flex-average" :src="imageSrc" alt="" @click="getData()" />
					</div>
				</el-form-item>
				<div class="isRegister" @click="toRegister">没有账号？去<span>注册</span></div>
				<el-form-item>
					<el-button class="submit-btn" @click="submitForm('ruleForm')">登录</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- 注册 -->
		<el-dialog :visible.sync="dialogRegister" width="25%" :modal-append-to-body="false" :show-close="true">
			<img class="dialog-logo" src="../assets/image/login-logo.png" alt="" />
			<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleRegister" class="demo-ruleForm" label-position="top">
				<el-form-item label="账号" prop="username">
					<el-input type="text" v-model="ruleForm.username" autocomplete="off" placeholder="请输入账号"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input type="password" v-model="ruleForm.password" autocomplete="off" placeholder="请输入密码"></el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="code">
					<div class="layout-flex">
						<el-input v-model="ruleForm.code" class="input-content" placeholder="请输入验证码"></el-input>
						<img class="code-img flex-average" :src="imageSrc" alt="" @click="getData()" />
					</div>
				</el-form-item>
				<div class="isRegister" @click="toLogin">已有账号？去<span>登录</span></div>
				<el-form-item>
					<el-button class="submit-btn" @click="submitRegister('ruleRegister')">注册</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
	<div v-else-if="isMobile"></div>
</template>

<script>
	import { captchaImage, login, getInfo, register } from "@/api/login";
	import { getSynthesisList } from "@/api/index";
	import VueCookies from "vue-cookies";

	export default {
		data() {
			var validateAccount = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请输入账号"));
				} else if (value.length < 2 || value.length > 20) {
					callback(new Error("账号需在2-20个字符之间"));
				} else {
					callback();
				}
			};
			var validatePass = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请输入密码"));
				} else if (value.length < 5 || value.length > 20) {
					callback(new Error("密码需在5-20个字符之间"));
				} else {
					callback();
				}
			};
			var checkCode = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("验证码不能为空"));
				} else {
					// if (value.length != 4) {
					// 	callback(new Error("验证码不正确"));
					// }
					callback();
				}
			};

			return {
				tabIndex: -1,
				dialogVisible: false,
				dialogRegister: false,

				ruleForm: {
					username: "",
					password: "",
					code: "",
					uuid: "",
				},
				rules: {
					username: [{ validator: validateAccount, trigger: "blur" }],
					password: [{ validator: validatePass, trigger: "blur" }],
					code: [{ validator: checkCode, trigger: "blur" }],
				},
				query: "",
				dataList: [],
				isLogin: null,
				imageSrc: "",
				avater: "",
				visible: false,
			};
		},
		created() {
			this.getData();

			this.isLogin = VueCookies.get("video-token");
		},
		watch: {
			// 监听路由的变化，动态更新 tabIndex
			$route(to, from) {
				this.getData();
				this.updateTabIndexByRoute();
			},
		},
		computed: {
			isMobile() {
				return this.$store.state.isMobile; // 从 Vuex 中获取 isMobile
			},
		},
		methods: {
			getData() {
				getSynthesisList().then((res) => {
					this.dataList = res.data;
					this.updateTabIndexByRoute();
				});
				captchaImage().then((res) => {
					this.ruleForm.uuid = res.data.uuid;
					this.imageSrc = `data:image/jpeg;base64,${res.data.img}`;
				});
				if (VueCookies.get("video-token") !== "") {
					getInfo().then((res) => {
						if (res.code == 200) {
							this.avater = res.data.user.avatar;
						}
					});
				}
			},
			chooseIndex(index) {
				this.tabIndex = index;
			},
			updateTabIndexByRoute() {
				const path = this.$route.path;
				const synthesisId = this.$route.query.synthesisId;

				// 如果路径是首页，tabIndex 设置为 -1
				if (path === "/") {
					this.tabIndex = -1;
				} else if (path === "/movie" && synthesisId) {
					// 如果是 /movie 且存在 synthesisId，找到对应的 index 并设置 tabIndex
					console.log(this.dataList);

					const index = this.dataList.findIndex((item) => item.synthesisId === synthesisId);
					console.log(index);

					this.tabIndex = index !== -1 ? index : 0; // 如果没找到对应的项，默认设置为第一个
				}
			},
			// 登录
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						login(this.ruleForm).then((res) => {
							if (res.code == 200 || res.code == 0) {
								this.$message({
									message: "登录成功",
									type: "success",
								});
								this.$cookies.set("video-token", res.data.token);
								this.dialogVisible = false;
								this.visible = false; // 重置 visible 状态
								this.$refs[formName].resetFields();
								this.isLogin = VueCookies.get("video-token");
							} else {
								this.$message.error(res.msg);
								this.getData();
							}
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			// 注册
			submitRegister(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						register(this.ruleForm).then((res) => {
							if (res.code == 200 || res.code == 0) {
								this.$message({
									message: "注册成功",
									type: "success",
								});
								this.dialogRegister = false;
								this.$refs[formName].resetFields();
								this.getData();
							} else {
								this.$message.error(res.msg);
								this.getData();
							}
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			onSearch() {
				if (this.query.trim()) {
					// 判断当前查询参数是否与输入的关键词相同
					if (this.$route.query.query !== this.query) {
						// 使用 `push` 或 `replace` 来导航
						this.$router.push({ name: "search", query: { query: this.query } });
					}
				}
			},

			logout() {
				this.visible = false;
				VueCookies.remove("video-token");
				this.isLogin = VueCookies.get("video-token");
			},
			toRegister() {
				this.$refs["ruleForm"].resetFields();
				this.dialogVisible = false;
				this.dialogRegister = true;
				this.getData();
			},
			toLogin() {
				this.dialogRegister = false;
				this.dialogVisible = true;
				this.getData();
			},
		},
	};
</script>

<style lang="scss" scoped>
	.head-container {
		width: 100vw;
		overflow: hidden;
		height: 81px;
		position: fixed;
		top: 0;
		left: 0;
		// padding: 0 30px;
		// box-sizing: border-box;
		// background-color: rgba(0, 0, 0, 0.5);
		z-index: 10;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.head-one {
			cursor: pointer;
			img {
				width: 131px;
				height: 38px;
			}
		}
		.head-two {
			margin-left: 60px;
			height: 100%;
			.head-content {
				margin: 0 20px;
				height: 100%;
				text-align: center;
				color: rgba(255, 255, 255, 0.6);
				// text-shadow: 1px 1px 2px black;
				font-size: 1rem;
				cursor: pointer;
				white-space: nowrap;
				&:hover {
					color: white;
				}
			}
			.actice {
				color: white;
			}
		}
		.search-content {
			margin-left: 100px;
			background: rgba(255, 255, 255, 0.3);
			border-radius: 25px 25px 25px 25px;
			border: 1px solid rgba(255, 255, 255, 0.5);
			input {
				width: 451px;
				height: 45px;
				border: none;
				background: none;
				padding: 0 8px;
				color: rgba(255, 255, 255, 0.6);
				font-size: 14px;
				outline-style: none;
			}
			input::-webkit-input-placeholder {
				color: rgba(255, 255, 255, 0.6);
			}
			.search-btn {
				img {
					width: 24px;
					height: 24px;
					margin-right: 8px;
					margin-left: 16px;
					cursor: pointer;
				}
				.search-cut {
					width: 1px;
					height: 14px;
					background: rgba(255, 255, 255, 0.2);
					border-radius: 1px 1px 1px 1px;
				}
			}
		}
		.history {
			cursor: pointer;
			white-space: nowrap;
			margin-right: 40px;

			img {
				width: 20px;
				height: 20px;
			}
			.history-txt {
				color: rgba(187, 187, 187, 1);
				font-size: 12px;
			}
		}
		.login-btn {
			cursor: pointer;
			width: 87px;
			height: 36px;
			border-radius: 8px;
			background: rgba(216, 229, 255, 0.3);
			color: white;
			img {
				width: 24px;
				height: 24px;
				margin-right: 6px;
			}
		}
		.avater {
			width: 36px;
			height: 36px;
			border: 1px solid rgba(255, 255, 255, 0.5);
			border-radius: 50%;
		}
		.dialog-logo {
			width: 171px;
			height: 50px;
			margin-bottom: 32px;
		}
	}
	::v-deep .el-dialog {
		border-radius: 8px;
	}
	::v-deep .el-dialog__header {
		padding: 0;
	}
	::v-deep .el-dialog__body {
		// padding: 58px 62px !important;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	::v-deep .el-input {
		width: 350px;
	}
	::v-deep .el-form-item__label {
		line-height: 0;
		margin-bottom: 6px;
	}
	.input-content {
		width: 65%;
	}
	.isRegister {
		cursor: pointer;
		span:hover {
			color: #fb3333;
		}
	}
	.submit-btn {
		width: 100%;
		height: 44px;
		background: linear-gradient(284deg, #fb3333 0%, #ffad4f 100%);
		box-shadow: 0px 0px 0px 0px rgba(18, 53, 81, 0.4);
		border-radius: 8px 8px 8px 8px;
		color: white;
		margin-top: 16px;
	}
	.code-img {
		height: 40px;
		margin-left: 10px;
		cursor: pointer;
	}
</style>
