import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/public.scss";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueCookies from "vue-cookies";

import videojs from "video.js";
import "video.js/dist/video-js.css"; // 引入video.js的css
import hls from "videojs-contrib-hls"; // 播放hls流需要的插件
// 视频播放器组件
import VideoPlayer from "vue-video-player";
//引入样式
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";
import "vue-video-player/src/custom-theme.css";
Vue.use(hls);
Vue.use(ElementUI);
Vue.use(VueCookies);
Vue.use(VideoPlayer);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
