import request from "@/utils/request.js";
/**
 * 查询轮播图
 * @returns
 */
export function getCarouselList(params) {
	return request({
		url: "/api/video/getCarouselList",
		method: "GET",
		params,
	});
}
/**
 * 获取首页小图
 * @returns
 */
export function getIndexThumbnail(params) {
	return request({
		url: "/api/video/getIndexThumbnail",
		method: "GET",
		params,
	});
}
/**
 * 获取综合分类列表
 * @returns
 */
export function getSynthesisList() {
	return request({
		url: "/api/video/getSynthesisList",
		method: "GET",
	});
}
/**
 * 获取视频列表,可以获取最新更新的和按照评分排序
 * @returns
 */
export function getVideoList(params) {
	return request({
		url: "/api/video/getVideoList",
		method: "GET",
		params,
	});
}
/**
 * 获取栏目信息and影视列表
 * @returns
 */
export function getColumnInfo(params) {
	return request({
		url: "/api/video/getColumnInfo",
		method: "GET",
		params,
	});
}
/**
 * 获取视频最近更新列表,详细信息
 * @returns
 */
export function getVideoLastList(params) {
	return request({
		url: "/api/video/getVideoLastList",
		method: "GET",
		params,
	});
}
/**
 * 获取视频列表，主要根据名字搜索
 * @returns
 */
export function getVideoListByName(params) {
	return request({
		url: "/api/video/getVideoListByName",
		method: "GET",
		params,
	});
}
/**
 * 获取综合分类下的类型分类
 * @returns
 */
export function getTypeList(params) {
	return request({
		url: "/api/video/getTypeList",
		method: "GET",
		params,
	});
}
/**
 * 获取排行榜
 * @returns
 */
export function getLeaderboardList(params) {
	return request({
		url: "/api/video/getLeaderboardList",
		method: "GET",
		params,
	});
}
/**
 * 获取榜单（type可选 1排行榜 2热播 3热映）
 * @returns
 */
export function getRankList(params) {
	return request({
		url: "/api/video/getRankList",
		method: "GET",
		params,
	});
}
/**
 * 通过综合分类id获取榜单
 * @returns
 */
export function getRankListBySynthesisId(params) {
	return request({
		url: "/api/video/getRankListBySynthesisId",
		method: "GET",
		params,
	});
}
/**
 * 获取网站信息
 * @returns
 */
export function getWebsiteInfo() {
	return request({
		url: "/api/video/getWebsiteInfo",
		method: "GET",
	});
}
/**
 * 获取首页栏目列表
 * @returns
 */
export function getIndexColumnList(params) {
	return request({
		url: "/api/video/getIndexColumnList",
		method: "GET",
		params,
	});
}
