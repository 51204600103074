import Vue from "vue";
import VueRouter from "vue-router";
// import VueCookies from "vue-cookies";
// import { MessageBox } from "element-ui";
Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "test",
		component: () => import("@/views/phone/test.vue"),
	},
	{
		path: "/test",
		name: "test",
		component: () => import("@/views/phone/test.vue"),
	},
	{
		path: "/index",
		name: "index",
		component: () => import("@/views/Index.vue"),
	},
	{
		path: "/phoneindex",
		name: "phoneindex",
		component: () => import("@/views/phone/index.vue"),
	},
	{
		path: "/detail",
		name: "detail",
		component: () => import("@/views/Detail.vue"),
	},
	{
		path: "/movie",
		name: "movie",
		component: () => import("@/views/Movie.vue"),
	},
	{
		path: "/play",
		name: "play",
		component: () => import("@/views/Play.vue"),
	},
	{
		path: "/recent",
		name: "recent",
		component: () => import("@/views/RecentUpdate.vue"),
	},
	{
		path: "/filter",
		name: "filter",
		component: () => import("@/views/Filter.vue"),
	},
	{
		path: "/search",
		name: "search",
		component: () => import("@/views/Search.vue"),
	},
	{
		path: "/ranking",
		name: "ranking",
		component: () => import("@/views/RankingList.vue"),
	},
	{
		path: "/history",
		name: "history",
		component: () => import("@/views/History.vue"),
	},
	{
		path: "/column",
		name: "column",
		component: () => import("@/views/Column.vue"),
	},
	{
		path: "/recentphone",
		name: "recentphone",
		component: () => import("@/views/phone/Recent.vue"),
	},
	{
		path: "/rank",
		name: "rank",
		component: () => import("@/views/phone/Rank.vue"),
	},
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/phone/Login.vue"),
	},
	{
		path: "/phonedetail",
		name: "phonedetail",
		component: () => import("@/views/phone/Detail.vue"),
	},
	{
		path: "/phoneSearch",
		name: "phoneSearch",
		component: () => import("@/views/phone/Search.vue"),
	},
	{
		path: "/phonePlay",
		name: "phonePlay",
		component: () => import("@/views/phone/Play.vue"),
	},
	{
		path: "/phoneHistory",
		name: "phoneHistory",
		component: () => import("@/views/phone/History.vue"),
	},
	{
		path: "/phoneColumn",
		name: "phoneColumn",
		component: () => import("@/views/phone/Column.vue"),
	},
];

const router = new VueRouter({
	routes,

	scrollBehavior(to, from, savedPosition) {
		// 当点击跳转时，滚动到页面顶部
		return { x: 0, y: 0 };
	},
});

// 路由守卫
// const whitePages = ["/", "/product"];
// router.beforeEach(async (to, from, next) => {
// 	// 判断是否有登录
// 	console.log(VueCookies.isKey("xiaomi-token"));

// 	if (VueCookies.isKey("xiaomi-token")) {
// 		next();
// 	} else {
// 		const isInWhiteList = whitePages.includes(to.path) || /^\/detail\/\d+$/.test(to.path);
// 		// 没有登录
// 		if (isInWhiteList) {
// 			// 在白名单里
// 			next();
// 		} else {
// 			// 不在白名单
// 			try {
// 				await MessageBox.confirm("未登录，请先登录", "提示", {
// 					type: "warning",
// 				});
// 				next("/");
// 			} catch (error) {
// 				next("/");
// 			}
// 		}
// 	}
// });
export default router;
