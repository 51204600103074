import request from "@/utils/request.js";
/**
 * 生成验证码
 * @returns
 */
export function captchaImage() {
	return request({
		url: "/captchaImage",
		method: "GET",
	});
}
/**
 * 登录方法
 * @returns
 */
export function login(data) {
	return request({
		url: "/api/user/login",
		method: "POST",
		data,
	});
}
/**
 * 注册
 * @returns
 */
export function register(data) {
	return request({
		url: "/api/user/register",
		method: "POST",
		data,
	});
}
/**
 * 获取用户信息
 * @returns
 */
export function getInfo() {
	return request({
		url: "/api/user/getInfo",
		method: "GET",
		isToken: true,
	});
}
