<template>
	<div id="app" :style="{ 'background-color': isMobile ? 'white' : '#090e1d' }">
		<div v-if="!isMobile">
			<!-- 头部 -->
			<tab-bar :style="{ backgroundColor: isScrolled ? 'rgba(9,14,29,0.8)' : '' }"></tab-bar>
			<router-view></router-view>
			<!-- 底部 -->
			<app-footer></app-footer>
		</div>
		<div v-if="isMobile">
			<phone-tab></phone-tab>
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import AppFooter from "./components/AppFooter.vue";
	import TabBar from "./components/TabBar.vue";
	import phoneTab from "./components/phoneTab.vue";
	export default {
		name: "App",
		components: {
			AppFooter,
			TabBar,
			phoneTab,
		},
		data() {
			return {
				isScrolled: false, // 用于控制 TabBar 是否有背景色
				isMobile: false,
			};
		},
		created() {
			this.checkDeviceType();
		},
		mounted() {
			this.checkDeviceType();
			// 监听滚动事件
			window.addEventListener("scroll", this.handleScroll);
			window.addEventListener("resize", this.checkDeviceType);
		},
		beforeDestroy() {
			// 组件销毁时移除滚动事件监听器
			window.removeEventListener("scroll", this.handleScroll);
		},
		methods: {
			handleScroll() {
				if (window.scrollY > 80) {
					// 可以根据需求调整 100 的值
					this.isScrolled = true;
				} else {
					this.isScrolled = false;
				}
			},
			checkDeviceType() {
				this.isMobile = window.matchMedia("(max-width: 500px)").matches; // 768px 是一个常见的平板设备宽度
				this.$store.dispatch("updateIsMobile", this.isMobile);
			},
		},
	};
</script>

<style lang="scss">
	body {
		margin: 0;
		width: 100vw;
		overflow: hidden;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
		position: relative; // 确保整个应用有一个相对定位，方便定位子元素
	}
	tab-bar {
		position: absolute; // 或 fixed，根据需要选择
		top: 0;
		left: 0;
		width: 100%;
		z-index: 10;
	}
	// phone-tab {
	// 	position: absolute; // 或 fixed，根据需要选择
	// 	top: 0;
	// 	left: 0;
	// 	z-index: 10;
	// }
	router-view {
		position: relative; // 给 router-view 设置相对定位
		z-index: 1; // 确保 router-view 在 TabBar 下面
	}
	:root {
		overflow-y: auto;
		overflow-x: hidden;
	}

	:root body {
		position: absolute;
	}
	a {
		text-decoration: none;
		color: #000;
	}
</style>
