<template>
	<div>
		<div class="foot-container">
			<div class="content">
				<!-- <div class="footer-content layout-justify-between" v-if="isFoot">
					<div class="foot-content" v-for="item in SynthesisList">
						<div class="foot-top">{{ item.synthesisName }}</div>
						<div class="layout-row foot-box">
							<div v-for="(typeItem, index) in item.typeList" class="foot-txt">{{ typeItem.typeName }}</div>
						</div>
					</div>
				</div>
				<div class="foot-cutline" v-if="isFoot"></div> -->
				<div class="foot-bottom layout-column layout-center">
					<div>{{ footList.copyrightStatement }}</div>
					<div class="bottom-txt">邮箱地址：{{ footList.email }}</div>
				</div>
			</div>
		</div>
		<!-- <div v-if="getIsMobile" class="mobile-content">
			<div class="foot-bottom layout-column layout-center">
				<div>{{ footList.copyrightStatement }}</div>
				<div class="bottom-txt">邮箱地址：{{ footList.email }}</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	import { getSynthesisList, getTypeList, getWebsiteInfo } from "@/api/index";
	// import { mapGetters } from "vuex";
	export default {
		data() {
			return {
				isFoot: false,
				SynthesisList: [],
				footList: [],
			};
		},
		created() {
			this.updateTabIndexByRoute();
			this.getData();
		},
		// computed: {
		// 	...mapGetters(["getIsMobile"]), // 映射 getter 到组件的计算属性
		// },
		watch: {
			// 监听路由的变化，动态更新 tabIndex
			$route(to, from) {
				this.updateTabIndexByRoute();
			},
		},
		methods: {
			updateTabIndexByRoute() {
				// 根据当前路由设置 tabIndex
				const path = this.$route.path;
				if (path === "/" || path === "/movie") {
					this.isFoot = true;
				} else {
					this.isFoot = false;
				}
			},
			getData() {
				getSynthesisList().then((res) => {
					this.SynthesisList = res.data.splice(0, 4);
					this.SynthesisList.map((item) => {
						return this.fetchTypeListForSynthesis(item.synthesisId);
					});
				});
				getWebsiteInfo().then((res) => {
					this.footList = res.data;
				});
			},
			fetchTypeListForSynthesis(synthesisId) {
				return new Promise((resolve, reject) => {
					getTypeList({ synthesisId }).then((res) => {
						// 假设返回的 data 是我们需要的类型列表
						const typeList = res.data;

						// 将返回的 typeList 保存到相应的 SynthesisList 项目中
						const synthesisItem = this.SynthesisList.find((item) => item.synthesisId === synthesisId);
						this.SynthesisList = [...this.SynthesisList];
						if (synthesisItem) {
							synthesisItem.typeList = typeList;
						}
					});
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.foot-container {
		width: 100vw;
		overflow-x: hidden;
		background: #121727;
		box-sizing: border-box;
		padding: 50px 0 40px 0px;
		.footer-content {
			// display: grid;
			// grid-template-columns: repeat(4, 1fr);
			// gap: 0 71px;
			.foot-content {
				width: 100%;
				height: 100%;
				margin-right: 35px;

				// padding: 0 50px 0 25px;
				box-sizing: border-box;
				&:nth-last-child(1) {
					border: none;
				}
				.foot-top {
					color: white;
					font-size: 18px;
					margin-bottom: 4px;
				}
				.foot-box {
					color: rgba(255, 255, 255, 0.6);
					.foot-txt {
						margin-right: 12px;
						margin-top: 18px;
					}
				}
			}
		}
		.foot-cutline {
			width: 100%;
			height: 1px;
			background: rgba(255, 255, 255, 0.1);
			margin-top: 50px;
			margin-bottom: 40px;
		}
		.foot-bottom {
			font-size: 14px;
			color: rgba(255, 255, 255, 0.5);
			.bottom-txt {
				margin-top: 16px;
			}
		}
	}
	.mobile-content {
		width: 100vw;
		background: #e4e4e4;
		box-sizing: border-box;
		padding: 40px 30px;
		.foot-bottom {
			font-size: 14px;
			.bottom-txt {
				margin-top: 16px;
			}
		}
	}
</style>
