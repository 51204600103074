import axios from "axios";
import { Message } from "element-ui";
import VueCookies from "vue-cookies";
const instance = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL || "",
});

// 请求拦截
instance.interceptors.request.use((config) => {
	const { isToken } = config;
	if (isToken) {
		config.headers["Authorization"] = `Bearer ${VueCookies.get("video-token")}`;
	}
	// 发送请求
	return config;
});
// 响应拦截
instance.interceptors.response.use(
	(res) => {
		// 请求成功
		switch (res.data.code) {
			case 401:
				// 清除 token
				VueCookies.remove("video-token");
				break;
			case 404:
				console.log("路径写错");
				break;
			case 422:
				console.log("字段错误，少传参数");
				break;
			case 500:
				console.log("服务端错误");
				break;
		}
		// 拆包
		return res.data;
	},
	(err) => {
		console.log(err);
		// 请求失败
		Message.error(err.message);
		// 抛出错误异常
		return Promise.reject(err.response);
	}
);
export default instance;
