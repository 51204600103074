import Vue from "vue";
import Vuex from "vuex";
// import { cartNum } from "@/api/shopping";
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		isMobile: false,
		tabIndex: -1,
	},
	getters: {
		getTabIndex: (state) => state.tabIndex,
		getIsMobile: (state) => state.isMobile,
	},
	mutations: {
		setIsMobile(state, value) {
			state.isMobile = value;
		},
		setTabIndex(state, index) {
			state.tabIndex = index;
		},
	},
	actions: {
		updateIsMobile({ commit }, value) {
			commit("setIsMobile", value);
		},
		updateTabIndex({ commit }, index) {
			commit("setTabIndex", index);
		},
	},
	modules: {},
});
